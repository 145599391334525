<template>
  <div class="modal-container">
    <div class="modal-wrapper">
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide>
          <div class="swipe-card">
            <div class="swipe-img1" />
            <div class="swipe-title">
              <b-badge variant="dark" class="mb-1">步驟1</b-badge>
              <div>警示後方車輛並報警</div>
            </div>
            <div class="swipe-text">
              閃雙黃燈或放故障標示，警示後方車輛避免二次車禍。<br />
              若對方無法移動或需要救護車，請於報警同時要求派遣救護車。
            </div>
            <div class="swipe-next-button clickable" @click="swiper.slideTo(1, 500, false)">
              Ok, 下一步
            </div>
            <div class="swipe-close clickable" @click="onClose" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swipe-card">
            <div class="swipe-img2" />
            <div class="swipe-title">
              <b-badge variant="dark" class="mb-1">步驟2</b-badge>
              <div>記錄現場</div>
            </div>
            <div class="swipe-text">
              照片是留存證據的最佳方式。建議您拍下「車牌」、「撞擊與損害處」、「兩車不同角度的多張合照」、「人員受傷照片」。
            </div>
            <div class="swipe-next-button clickable" @click="swiper.slideTo(2, 500, false)">
              Ok, 下一步
            </div>
            <div class="swipe-close clickable" @click="onClose" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swipe-card">
            <div class="swipe-img3" />
            <div class="swipe-title">
              <b-badge variant="dark" class="mb-1">步驟3</b-badge>
              <div>確認是否需要現場和解</div>
            </div>
            <div class="swipe-text">
              除了藉由保險處理外，下列條件都具備的情況下，可以考慮與對方和解：<br />
              <br />1. 雙方都沒有受傷 <br />2. 雙方都確認不需要由保險公司理賠 <br />3.
              雙方達成和解條件共識
            </div>
            <div class="swipe-next-button clickable" @click="swiper.slideTo(3, 500, false)">
              Ok, 下一步
            </div>
            <div class="swipe-close clickable" @click="onClose" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swipe-card">
            <div class="swipe-img4" />
            <div class="swipe-title">
              <b-badge variant="dark" class="mb-1">步驟4</b-badge>
              <div>等待筆錄</div>
            </div>
            <div class="swipe-text">
              在等待的這段時間，不妨先看一下PAMO為您準備的文章，瞭解警察待會會進行的步驟、做筆錄需要知道的事等等。也建議通知公司或親人，說明自己遲到的原因，但記得告訴對方自己會妥適處理。
            </div>
            <a
              target="_blank"
              :href="'https://www.pamolaw.com/%e8%bb%8a%e7%a6%8d%e5%a0%b1%e8%ad%a6%e5%81%9a%e7%ad%86%e9%8c%84%e8%a9%b2%e6%b3%a8%e6%84%8f%e4%bb%80%e9%ba%bc%ef%bc%9f?openExternalBrowser=1'"
            >
              <div class="swipe-link-button clickable">了解做筆錄須知</div>
            </a>
            <div class="swipe-next-button clickable" @click="onClose">我瞭解了</div>
            <div class="swipe-close clickable" @click="onClose" />
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
  props: ['onClose'],
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
  },
  destroyed() {
    document.body.style.position = '';
    document.body.style.width = '';
  },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
        },
      },
    };
  },
};
</script>

<style lang="less" scoped="true">
@import '@/assets/lessjs/variables.less';
.modal-container {
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.8);
  }
}
.modal-wrapper {
  position: absolute;
  background: transparent;
  z-index: 2;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 100vh;
  height: var(--app-height);
  padding: 40px 10px 20px 10px;
  display: flex;
}

.swiper-slide {
  display: flex;
}

.swipe-card {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background-color: #fff;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 40px;
  position: relative;
  overflow: hidden;
}

.swipe-close {
  position: absolute;
  right: 25px;
  top: 25px;
  width: 25px;
  height: 25px;
  opacity: 0.8;
}

.swipe-close:before,
.swipe-close:after {
  position: absolute;
  left: 12px;
  content: ' ';
  height: 26px;
  width: 2px;
  background-color: #333;
}

.swipe-close:before {
  transform: rotate(45deg);
}
.swipe-close:after {
  transform: rotate(-45deg);
}

.swipe-img1 {
  width: 100%;
  height: 0;
  padding-top: 54.6%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../assets/tutorial_1@2x.png');
}

.swipe-img2 {
  width: 100%;
  height: 0;
  padding-top: 54.6%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../assets/tutorial_2@2x.png');
}

.swipe-img3 {
  width: 100%;
  height: 0;
  padding-top: 54.6%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../assets/tutorial_3@2x.png');
}

.swipe-img4 {
  width: 100%;
  height: 0;
  padding-top: 54.6%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../assets/tutorial_4@2x.png');
}

.swipe-title {
  margin-top: 24px;
  color: #3c3c3c;
  font: normal normal bold 24px/29px Helvetica Neue;
  text-align: center;
}

.swipe-text {
  margin-top: 20px;
  text-align: center;
  flex: 1;
  font: normal normal bold 0.9em Helvetica Neue;
  color: #7e7e7e;
  padding: 0 20px;
  min-height: 70px;
  overflow: auto;
}

.swipe-link-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 58px;
  background: #b49d5f 0% 0% no-repeat padding-box;
  font: normal normal normal 14px/16px Helvetica Neue;
  color: #ffffff;
}

.swipe-next-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 58px;
  background: #404040 0% 0% no-repeat padding-box;
  font: normal normal normal 14px/16px Helvetica Neue;
  color: #ffffff;
}
</style>
