<template>
  <div>
    <div v-if="!requesting && !profileRequesting" class="container">
      <div class="wrapper">
        <div v-if="profile.license && remainDays > 0">
          <!-- 付費license -->
          <!-- <router-link class="licenseBlock clickable" to="/payment" v-if="profile.license.orderId">
            <div class="licenseFull p-2 justify-content-end">
              <div class="licenseName">
                <h6 class="mb-0">會員專區</h6>
                <h6 class="mb-0">{{ profile.license.endingTime }}</h6>
              </div>
            </div>
          </router-link> -->
          <!-- 授權license -->
          <!-- <div class="licenseBlock clickable" @click="planModalShow = true" v-else>
            <div class="licenseTop p-2">
              <div class="licenseType">授權序號方案</div>
              <div class="licenseName">
                {{ profile.license.name }}
              </div>
            </div>
            <div v-if="profile.license" class="licenseBottom">
              <div class="licenseCountDownText" style="margin-top: 2px">還剩</div>
              <div class="licenseCountDownNum" v-if="remainDays < 365">
                {{ remainDays }}
              </div>
              <div class="licenseCountDownNum" v-else>
                {{ Math.floor(remainDays / 365) }}
              </div>
              <div class="licenseCountDownText" style="margin-top: 12px">
                <span v-if="remainDays < 365">天</span>
                <span v-else>年</span>
              </div>
            </div>
          </div> -->
        </div>
        <!-- 未付費 -->
        <!-- <div class="licenseBlock clickable" @click="introModalShow = true" v-else>
          <div class="licenseTop p-2">
            <div class="licenseType">您不是 PAMO 會員</div>
          </div>
          <div class="licenseBottom p-2">馬上購買</div>
        </div> -->
        <!-- <div class="head">
          <div class="row">
            <div class="col-6 ml-2 mt-5 mb-5">
              <img src="@/assets/logo_home@2x.png" id="logo" />
            </div>
          </div>
        </div> -->
        <div class="row my-4 align-items-center">
          <div class="col-auto"><img src="@/assets/home_logo.png" id="home-logo" /></div>
          <div class="col-auto">
            <h1>Hello, {{ profile.firstName }}</h1>
          </div>
        </div>
        <div v-if="profile.license && remainDays > 0" class="mb-3">您的方案</div>
        <div class="planBlock p-3 mb-3">
          <div class="row mb-2 align-items-center">
            <div class="col"><img src="@/assets/plan_logo_1.png" /></div>
            <div class="col-auto">
              <div>
                <h1 v-if="profile.license && remainDays > 0" class="serif text-white text-center">
                  會籍1年
                </h1>
                <div v-else class="serif text-center">您還不是會員</div>
              </div>
              <div class="text-center" v-if="profile.license">
                <span>{{ format(parseISO(profile.license.endingTime), 'yyyy/MM/dd') }}</span>
                <span v-if="profile.license.orderId">自動續約</span>
                <span v-else>到期</span>
              </div>
            </div>
          </div>
          <hr />
          <div class="row mb-2 align-items-center">
            <div class="col">
              <div>車禍問題線上問到飽</div>
              <div>不限次數、不限時</div>
              <div>可使用線上和解書</div>
            </div>
            <div class="col-auto text-right">
              <a href="javascript:;" @click="introModalShow = true">查看詳情</a>
            </div>
          </div>
          <div>
            <a
              href="https://lin.ee/fparSMT"
              target="_blank"
              v-if="profile.license && remainDays > 0"
            >
              <Button name="開始諮詢" isHighlighted="true" />
            </a>
            <router-link to="/payment/order" v-else>
              <Button name="NT$99+1/月 無痛入手" isHighlighted="true" />
            </router-link>
          </div>
        </div>
        <div class="planBlock p-3 mb-3">
          <div class="row mb-2 align-items-center">
            <div class="col"><img src="@/assets/plan_logo_2.png" /></div>
            <div class="col-auto">
              <h4 class="serif text-white text-center">會籍期間限定</h4>
              <div v-if="profile.license">
                <div class="text-center">
                  <span>{{ format(parseISO(profile.license.endingTime), 'yyyy/MM/dd') }}</span>
                  <span v-if="profile.license.orderId">續贈1200點</span>
                  <span v-else>到期</span>
                </div>
              </div>
              <div v-else class="serif text-center">您還不是會員</div>
            </div>
          </div>
          <hr />
          <div class="row mb-2 align-items-center">
            <div class="col">
              <div>折抵實體律師費</div>
              <div>車禍以外也服務</div>
              <div>每年贈1200點，可累積</div>
            </div>
            <div class="col-auto text-right">
              <a href="javascript:;" @click="planModalShow = true">查看詳情</a>
            </div>
          </div>
          <div>
            <a href="https://lin.ee/fparSMT" target="_blank">
              <Button
                v-if="profile.license && remainDays > 0"
                name="查詢/兌換"
                isHighlighted="true"
              />
            </a>
          </div>
        </div>
        <div class="mb-3">其他功能</div>
        <Menu :menu="menu" />
        <div class="text-center p-2">
          <div class="row justify-content-center">
            <div class="col-auto"><a href="https://lin.ee/SbFLz7Y">與 PAMO 聯繫</a></div>
            <div class="col-auto">
              <a href="https://www.pamolaw.com/terms/" target="_blank">服務條款</a>
            </div>
          </div>
          <p><small>www.pamolaw.com</small></p>
        </div>
      </div>
    </div>
    <IntroModal v-if="introModalShow" :onClose="closeIntroModal" />
    <AccidentGuideModal v-if="guideModalShow" :onClose="closeGuideModal" />
    <PlanModal v-if="planModalShow" :onClose="closePlanModal" />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { parseISO, differenceInDays, format } from 'date-fns';
import { payment, auth } from '@/mixins';
import Menu from '@/components/Menu.vue';
import Button from '@/elements/Button.vue';
import IntroModal from '@/components/IntroModal.vue';
import PlanModal from '@/components/PlanModal.vue';
import AccidentGuideModal from '@/components/AccidentGuideModal.vue';

export default {
  mixins: [payment, auth],
  components: {
    Menu,
    IntroModal,
    AccidentGuideModal,
    PlanModal,
    Button,
  },
  data() {
    return {
      introModalShow: false,
      guideModalShow: false,
      planModalShow: false,
      menu: [
        {
          name: '線上和解書',
          id: 'contract',
          notes: '有法律保障的線上車禍和解',
          target: 'menu',
          highlighted: true,
        },
        {
          name: '下載事故隨車小卡',
          id: 'cardwithcar',
          externalLink: true,
          target: './card-with-car.pdf',
          highlighted: true,
          notes: '您隨車的律師護身符',
        },
        {
          name: '會員資料',
          id: '',
          target: 'user',
          highlighted: false,
        },
        {
          name: '保險資訊',
          id: 'insurance',
          target: 'insurance',
          highlighted: false,
        },
      ],
    };
  },
  mounted() {
    console.log('license', this.license);
    console.log('profile', this.profile);
    console.log('payment', this.payment);
    console.log(this.profile.license);
  },
  computed: {
    ...mapState({
      profile: (state) => state.auth.profile,
      payment: (state) => state.payment.payment,
      license: (state) => state.payment.license,
      requesting: (state) => state.auth.requesting,
      profileRequesting: (state) => state.auth.profileRequesting,
    }),
    remainDays() {
      return differenceInDays(parseISO(this.profile.license.endingTime), new Date());
    },
  },
  watch: {
    profile: {
      // immediate: true,
      handler() {
        if (this.profile.license && this.remainDays > 0) {
          if (this.profile.license.orderId) {
            // alert('信用卡會員');
          } else {
            // alert('序號會員');
            this.menu = this.menu.map((v) => {
              if (v.name === '付費資訊') {
                return {
                  name: v.name,
                  clickEvent: true,
                  onClick: () => {
                    this.planModalShow = true;
                  },
                };
              }
              return v;
            });
          }
        } else {
          // alert('過期會員');
          this.menu = this.menu.map((v) => {
            if (
              v.name === '啟用車禍線上律師' ||
              v.name === '線上和解書' ||
              v.name === '我的 PAMO POINT'
            ) {
              return {
                name: v.name,
                clickEvent: true,
                highlighted: true,
                id: v.id,
                notes: v.notes,
                onClick: () => {
                  this.introModalShow = true;
                },
              };
            }
            if (v.name === '付費資訊') {
              return {
                name: v.name,
                clickEvent: true,
                onClick: () => {
                  this.introModalShow = true;
                },
              };
            }
            return v;
          });
        }
      },
    },
  },
  methods: {
    parseISO,
    differenceInDays,
    format,
    closeGuideModal() {
      this.guideModalShow = false;
    },
    closeIntroModal() {
      this.introModalShow = false;
    },
    closePlanModal() {
      this.planModalShow = false;
    },
  },
};
</script>
<style lang="less">
@import '@/assets/lessjs/variables.less';
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@900&display=swap');
#logo {
  max-width: 200px;
}

.licenseBlock {
  position: absolute;
  top: 33px;
  right: 0;
}

.text-white {
  color: white;
}

.serif {
  font-family: 'Noto Serif TC', serif;
  font-weight: 900;
  font-style: normal;
}

.licenseFull {
  background-color: #b19d67;
  display: flex;
  align-items: center;
  justify-content: end;
  height: 70px;
  width: 150px;
  border-radius: 10px 0 0 10px;
  background-position: left;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-blend-mode: luminosity;
  overflow: hidden;
  position: relative;
  background-image: url('../assets/bg_plan.png');
  .licenseName {
    z-index: 1;
    position: relative;
  }
  &::after {
    content: '';
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background: rgb(180, 157, 95);
    background: linear-gradient(
      90deg,
      rgba(180, 157, 95, 0) 0%,
      rgba(180, 157, 95, 1) 65%,
      rgba(180, 157, 95, 1) 100%
    );
  }
}

.licenseTop {
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50%;
  background-color: @dark-grey;
  border-top-left-radius: 8px;
}

.licenseBottom {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 2px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50%;
  background-color: #b19d67;
  border-bottom-left-radius: 8px;
  font-size: 14px;
  color: #ffffff;
}

.licenseType {
  font-size: 12px;
  color: #ffffff;
  transform: scale(0.8);
}

.licenseName {
  font-size: 12px;
  color: #ffffff;
}

.licenseCountDownText {
  font-size: 12px;
  color: #ffffff;
  transform: scale(0.8);
  letter-spacing: 2px;
  writing-mode: vertical-lr;
}

.licenseCountDownNum {
  font-size: 28px;
  color: #ffffff;
}

.planBlock {
  background-color: #2d2d2d;
  border-radius: 20px;
}

.planBlock img {
  max-width: 150px;
  width: 100%;
}
#home-logo {
  width: 50px;
}
</style>
